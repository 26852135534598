<template>
    <modal ref="trasladarStock" titulo="Traslados de Stock" tamano="modal-xl" icon="Filledoutline" no-aceptar>
        <div class="row justify-center">
            <p>Algunos productos tienen pendiente la gestion de traslado de Stock </br> Te recomendamos realizar la gestion en los Centros de distribucion correspondientes.</p>
        </div>
        <div class="d-flex flex-wrap">
            <div v-for="(data, idx) in productos" :key="idx" class="my-3">
                <div class="card-prod border br-10 p-2 m-2">
                    <div class="d-flex">
                        <img class="obj-cover" width="90" height="90" :src="data.imagen" alt="" />
                        <div class="f-14">
                            <p class="ucfirst f-600">{{ data.producto }}</p>
                            <p class="mt-2">{{ data.presentacion }}</p>
                        </div>
                    </div>
                    <div class="gap-2 mt-3">
                        <p class="f-600">Cedis Origen</p>
                        <div v-for="(c,key) in data.cedis" :key="key">
                            <div class="row mx-0 d-middle justify-content-between mt-2">
                                <span class="f-14">{{ c.nombre_cedis }}</span>
                                <span v-if="c.estado_origen == 0" class="bg-warning px-2 br-10 text-black2">Pendiente</span>
                                <span v-if="c.estado_origen ==2" class="bg-danger px-2 br-10 text-white">Rechazado</span>
                            </div>
                            <span class="f-500 f-15">{{ agregarSeparadoresNumero(c.cantidad) }} Und.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            productos:[]
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
        }),
    },
    methods: {
        toggle(productos){
            this.productos = productos
            this.$refs.trasladarStock.toggle()
        },
    }
}
</script>

<style lang="css" scoped>
.card-prod{
    width: 330px;
}
.text-aqua{
    color: #03D6BC;
}
</style>
